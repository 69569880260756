var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"เริ่ม","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.startDate.$dirty && _vm.$v.startDate.$error}
      ]},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"ถึง","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.endDate.$dirty && _vm.$v.endDate.$error}
      ]},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6 col-sm-4",attrs:{"label":"เครื่องจักร"},model:{value:(_vm.machineSearch),callback:function ($$v) {_vm.machineSearch=$$v},expression:"machineSearch"}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-4",attrs:{"label":"สินค้า"},model:{value:(_vm.inventorySearch),callback:function ($$v) {_vm.inventorySearch=$$v},expression:"inventorySearch"}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-4",attrs:{"label":"แจ้งซ่อม"},model:{value:(_vm.docSearch),callback:function ($$v) {_vm.docSearch=$$v},expression:"docSearch"}})],1),_c('button',{staticClass:"btn btn-warning mb-3",on:{"click":_vm.fetchData}},[_vm._v(" ค้นหา ")]),_c('sgv-table',{attrs:{"rKey":_vm.rKey,"items":_vm.items,"headers":_vm.headers,"toolbars":_vm.toolbars,"filter":_vm.filter,"options":_vm.options,"rowsPerPage":_vm.rowsPerPage},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var hidden = ref.hidden;
return [_c('tr',[(hidden.id)?_c('td',{staticClass:"no-wrap"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toInventory(item)}},[_vm._v(" "+_vm._s(item.code)+" ")]),_c('small',[_c('div',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text-info"},[_vm._v(" ชั่วโมง: "+_vm._s(_vm._f("comma")(item.workingHour))+" ")])])],1):_vm._e(),(hidden.inventory)?_c('td',{staticClass:"no-wrap"},[_c('small',_vm._l((item.inventories),function(inventory,idx){return _c('div',{key:inventory.id},[_vm._v(" "+_vm._s(idx+1)+". "+_vm._s(inventory.code)+" ("+_vm._s(inventory.name)+") "),_c('div',{},[_c('span',{staticClass:"text-success px-1"},[_vm._v("จำนวน: "+_vm._s(_vm._f("comma")(inventory.total)))]),_c('span',{staticClass:"text-info px-1"},[_vm._v("ชั่วโมง: "+_vm._s(_vm._f("comma")(inventory.workingHour)))]),_c('span',{staticClass:"text-warning"},[_vm._v("วินาที/หน่วย: "+_vm._s(inventory.productionRates.join(', ')))])])])}),0)]):_vm._e(),(hidden.docRepairs)?_c('td',[_c('small',_vm._l((item.docRepairs),function(doc,idx){return _c('div',{key:doc.id},[_c('router-link',{staticClass:"text-decoration-none no-wrap",attrs:{"to":_vm.toDoc(doc)}},[_vm._v(" "+_vm._s(idx+1)+". "+_vm._s(doc.code)+" "+_vm._s(doc.name)+" ")]),_c('div',{staticClass:"text-primary pre-line"},[_vm._v(_vm._s(doc.remark))])],1)}),0)]):_vm._e(),(hidden.machineRepairs)?_c('td',[_c('small',_vm._l((item.machineRepairs),function(doc,idx){return _c('div',{key:doc.id},[_c('router-link',{staticClass:"text-decoration-none no-wrap",attrs:{"to":_vm.toDoc(doc)}},[_vm._v(" "+_vm._s(idx+1)+". "+_vm._s(doc.code)+" "+_vm._s(doc.name)+" ")]),_c('div',{staticClass:"text-primary pre-line"},[_vm._v(_vm._s(doc.remark))])],1)}),0)]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }