<template>
  <div class="">
    <div class="form-row">
      <sgv-input-select
        class="col-12"
        :options="options"
        v-model="selected"
        select="value">
        <template slot-scope="item">{{item.text}}</template>
      </sgv-input-select>
    </div>

    <component
      :is="selected"
      :docType="docType"
      :templateType="templateType">
    </component>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListReportGroup from '@/views/doc_core/components/ListReportGroup'
import ListReportStockIn from './ListReportStockIn'
import ListReportStockOut from './ListReportStockOut'
import ListReportMachine from './ListReportMachine'
import ListReportLedger from '@/views/doc_core/components/ListReportLedger'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}Report`,
      rFields: ['selected'],
      options: [
        {text: 'รายงานกลุ่ม', value: 'ListReportGroup'},
        {text: 'รายงานรับเข้า', value: 'ListReportStockIn'},
        {text: 'รายงานเบิก', value: 'ListReportStockOut'},
        {text: 'รายงานเครื่องจักร', value: 'ListReportMachine'},
        {text: 'รายงานบัญชี', value: 'ListReportLedger'},
      ],
      selected: 'ListReportGroup'
    }
  },
  watch: {
    selected: 'setRetaining'
  },
  components: {
    ListReportGroup,
    ListReportStockIn,
    ListReportStockOut,
    ListReportMachine,
    ListReportLedger
  }
}
</script>

<style lang="css" scoped>
</style>
