<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-4"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-4"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-4"
        v-model="search"
        label="ตัวกรอง">
      </sgv-input-text>
    </div>

    <button class="btn btn-warning mb-3" @click="fetchData">
      ค้นหา
    </button>

    <div class="table-responsive" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <th>สินค้า</th>
            <th>จำนวน</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td>
              {{item.code}}<br>
              <small class="text-primary">{{item.name}}</small>
            </td>
            <td>
              <span :class="{'text-success': item.total > 0, 'text-danger': item.total < 0}">
                {{item.total}}
              </span>
              {{item.unit}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_STOCK_IN } from './graph'


export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      items: null,
      startDate: '',
      endDate: '',
      search: '',
      rKey: `Doc${this.$form.capitalize(this.docType)}ListReportStockIn`,
      rFields: ['startDate', 'endDate', 'search'],
    }
  },
  validations: {
    startDate: {required},
    endDate: {required}
  },
  methods: {
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_STOCK_IN(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.startDate,
          endDate: this.endDate,
          search: this.search
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.rows
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
