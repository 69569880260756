<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>
    </div>

    <div class="form-row">
      <sgv-input-text
        class="col-6 col-sm-4"
        v-model="machineSearch"
        label="เครื่องจักร">
      </sgv-input-text>

      <sgv-input-text
        class="col-6 col-sm-4"
        v-model="inventorySearch"
        label="สินค้า">
      </sgv-input-text>

      <sgv-input-text
        class="col-6 col-sm-4"
        v-model="docSearch"
        label="แจ้งซ่อม">
      </sgv-input-text>
    </div>

    <button class="btn btn-warning mb-3" @click="fetchData">
      ค้นหา
    </button>

    <sgv-table
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :toolbars="toolbars"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.id" class="no-wrap">
            <router-link
              class="text-decoration-none"
              :to="toInventory(item)">
              {{item.code}}
            </router-link>
            <small>
              <div class="text-primary">
                {{item.name}}
              </div>
              <div class="text-info">
                ชั่วโมง: {{item.workingHour | comma}}
              </div>
            </small>
          </td>
          <td v-if="hidden.inventory" class="no-wrap">
            <small>
              <div
                v-for="(inventory, idx) in item.inventories"
                :key="inventory.id">
                {{idx+1}}. {{inventory.code}} ({{inventory.name}})
                <div class="">
                  <span class="text-success px-1">จำนวน: {{inventory.total | comma}}</span>
                  <span class="text-info px-1">ชั่วโมง: {{inventory.workingHour | comma}}</span>
                  <span class="text-warning">วินาที/หน่วย: {{inventory.productionRates.join(', ')}}</span>
                </div>
              </div>
            </small>
          </td>
          <td v-if="hidden.docRepairs">
            <small>
              <div
                v-for="(doc, idx) in item.docRepairs"
                :key="doc.id">
                <router-link
                  class="text-decoration-none no-wrap"
                  :to="toDoc(doc)">
                  {{idx+1}}. {{doc.code}} {{doc.name}}
                </router-link>
                <div class="text-primary pre-line">{{doc.remark}}</div>
              </div>
            </small>
          </td>
          <td v-if="hidden.machineRepairs">
            <small>
              <div
                v-for="(doc, idx) in item.machineRepairs"
                :key="doc.id">
                <router-link
                  class="text-decoration-none no-wrap"
                  :to="toDoc(doc)">
                  {{idx+1}}. {{doc.code}} {{doc.name}}
                </router-link>
                <div class="text-primary pre-line">{{doc.remark}}</div>
              </div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_MACHINE } from './graph'


export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      items: [],
      startDate: '',
      endDate: '',
      machineSearch: '',
      inventorySearch: '',
      docSearch: '',
      rKey: `Doc${this.$form.capitalize(this.docType)}ListReportMachine`,
      rFields: ['startDate', 'endDate', 'search', 'filter', 'options'],
      headers: [
        {text: 'เครื่องจักร', value: 'id'},
        {text: 'สินค้า', value: 'inventory'},
        {text: 'แจ้งซ่อม (ผลิต)', value: 'docRepairs'},
        {text: 'แจ้งซ่อม (เครื่องจักร)', value: 'machineRepairs'},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'inventory', 'docRepairs', 'machineRepairs'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  validations: {
    startDate: {required},
    endDate: {required}
  },
  computed: {
    toolbars () {
      const arr = [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
      ]
      return arr
    },
    rowsPerPage () {
      return [
        { text: 'ทั้งหมด', value: null },
      ]
    },
  },
  methods: {
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_MACHINE(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.startDate,
          endDate: this.endDate,
          machineSearch: this.machineSearch,
          inventorySearch: this.inventorySearch,
          docSearch: this.docSearch,
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.rows
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    toInventory (item) {
      return {
        name: `Inventory${this.$form.capitalize(item.type)}Detail`,
        params: {inventoryId: item.id},
        query: {redirect: this.$route.name}
      }
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id},
        query: {redirect: this.$route.name}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
